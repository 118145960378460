.pageHeight {
	min-height: 100vh;
}

.main-content {
	min-height: calc(100vh - 276px);
}

.button3d {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	color: #fff;
	background-color: #0080fe;
	border: none;
	border-radius: 8px;
	box-shadow: 0 4px #005bb5;
	cursor: pointer;
	transition: all 0.2s ease;
}

.button3d:active {
	box-shadow: 0 2px #005bb5;
	transform: translateY(2px);
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideInFromRight {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideInFromMiddle {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.slide-in-bottom {
	animation: slideInFromBottom 1.1s ease-out forwards;
}

.slide-in-left {
	animation: slideInFromLeft 1.7s ease-out forwards;
}

.slide-in-right {
	animation: slideInFromRight 1.7s ease-out forwards;
}

.slide-in-middle {
	animation: slideInFromMiddle 1.6s ease-out forwards;
}

@keyframes floatA {
	from {
		transform: translate(-50px, -50px);
		opacity: 0;
	}
	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

@keyframes floatB {
	from {
		transform: translate(50px, -50px);
		opacity: 0;
	}
	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

@keyframes floatC {
	from {
		transform: translate(-50px, 50px);
		opacity: 0;
	}
	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

@keyframes floatD {
	from {
		transform: translate(50px, 50px);
		opacity: 0;
	}
	to {
		transform: translateY(0, 0);
		opacity: 1;
	}
}

@keyframes floatUp {
	from {
		transformy: translate(50px);
		opacity: 0;
	}
	to {
		transformy: translateY(0);
		opacity: 1;
	}
}

.floatA {
	opacity: 0;
}

.floatB {
	opacity: 0;
}

.floatC {
	opacity: 0;
}

.floatD {
	opacity: 0;
}

.floatUp {
	opacity: 0;
}

.animate .floatA {
	animation: floatA 1s ease-out forwards;
}

.animate .floatB {
	animation: floatB 1.2s ease-out forwards;
}

.animate .floatC {
	animation: floatC 1.2s ease-out forwards;
}

.animate .floatD {
	animation: floatD 1.2s ease-out forwards;
}

.animate .floatUp {
	animation: floatD 1.2s ease-out forwards;
}

@keyframes slideUp {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.pc {
	opacity: 0;
}

.slide-up {
	animation: slideUp 1.5s ease-out forwards;
}

.features-background {
	position: relative;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
}

.features-background::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: var(--background-image);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed; /* Fix the background image */
	opacity: 0.1; /* Set the opacity to a high value */
	z-index: -1; /* Ensure the background is behind the content */
}

.features-background > div {
	position: relative;
	z-index: 1; /* Ensure the content is above the background */
	opacity: 1; /* Ensure the content is fully opaque */
}

.navbar-mobile {
	z-index: 1; /* Ensure the content is above the background */
	opacity: 1; /* Ensure the content is fully opaque */
}

.colorHomeText {
	color: #074f9b;
}
.colorMyText {
	color: #052852;
}

.colorDealText {
	color: #25a4fc;
}

.colorTeamsText {
	color: #074f9b;
}

.colorMDTText {
	color: #0080fe;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 3s ease-in-out forwards;
}
